main {
  align-content: center;
  box-sizing: border-box;
  display: grid;
  font-family: 'Lato', sans-serif;
  font-family: 'Roboto', sans-serif;
  font-family: 'Roboto Slab', serif;
  line-height: 1.65;
  margin: 0 auto;
  max-width: 2000px;
  min-height: 100vh;
  padding: 72px 0;
  text-align: left;
  background-image: url(./seaPhoto.jpg);
  color:#fff;
}
h1 {
  font-size: 45px;
  color:#000;
}
h2 {
  margin-top: 1.5em;
  color:#000;
  padding: 10px;
}
h3{
  color:#000;
  padding: 40px;

}
p {
  font-size: 16px;
}
a {
  border-bottom: 1px solid white;
  color: #0076ff;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease;
}
a:hover {
  border-bottom: 1px solid #0076ff;
}
code,
pre {
  color: #d400ff;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace, serif;
  font-size: 0.92em;
}
code:before,
code:after {
  content: '\`';
}
* {
  box-sizing: border-box;
}

.row {
  display: flex;
}

/* Create two equal columns that sits next to each other */
.column {
  flex: 50%;
  padding: 20px;
  height: 300px; /* Should be removed. Only for demonstration */
}